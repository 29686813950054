import xhr from './xhr/';
/**
 * 质检中心相关的 API
 */
class QualityService {
	// 质监中心搜索首页
	getQualitySelect(data) {
		return xhr({
			method: 'get',
			url: '/quality/index',
			params: data,
		});
	}

	// 搜索
	getQualityList(data) {
		return xhr({
			method: 'get',
			url: '/quality/list',
			params: data,
		});
	}

	// 获取某人所有的促进记录
	getRecordList(data) {
		return xhr({
			method: 'get',
			url: '/quality/list_member',
			params: data,
		});
	}

	// 删除问题或记录
	deleteBoost(data) {
		return xhr({
			method: 'post',
			url: '/quality/delete',
			params: data,
		});
	}

	// 获取某人某个促进记录详情
	getRecordDetail(data) {
		return xhr({
			method: 'get',
			url: '/quality/detail',
			params: data,
		});
	}

	// 开始促进
	getStart(data) {
		return xhr({
			method: 'get',
			url: '/quality/done_page',
			params: data,
		});
	}

	// 完成促进
	qualityDone(data) {
		return xhr({
			method: 'post',
			url: '/quality/done',
			params: data,
		});
	}
}

// 实例化后导出，全局单例
export default new QualityService();
